* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Montserrat" !important;
}
*::-webkit-scrollbar {
    display: none;
}
.MuiFileInput-placeholder{
    color:white !important;
}

.MuiInputBase-input ::placeholder{
    color:white !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.container-preregister{
    
    background: url("../assets/main-bg.jpg") no-repeat fixed center / cover;
    height: 100vh;
    width: 100vw;
}
body {
    height: 100vh;
    width: 100vw;
}

#root{
    height: 100vh;
    width: 100vw;
}

.tron-logo {
    background: url("../assets/tron-logo.svg")  left top / contain fixed no-repeat !important;
}

.MuiInputAdornment-root svg{
    fill:white !important;
}

@media only screen and (max-width: 1920px) {
    .box-preregister{
        margin-top: 30px !important;
        top:30px !important;
    }
    
}
@media only screen and (max-width: 1440px) {
    .big-font{
        font-size: 18px !important;
    }
    .small-font{
        font-size: 14px !important;
    }
    .box-pregister{
        margin-right: 50px !important;
    }
    .height-line-big{
        line-height: 60px !important;
    }
    p{
        margin: 15px 0 !important
    }
    .box-pregister{
        margin-top: 10px !important;
        top:10px !important;
    }
}
@media only screen and (max-width: 1280px) {
    .big-font{
        font-size: 14px !important;
        margin-top: -5px !important;
    }
    .small-font{
        font-size: 10px !important;
    }
    .box-pregister{
        margin-right: 50px !important;
    }
    .height-line-big{
        line-height: 30px !important;
    }
    p{
        margin: 10px 0 !important
    }
    .box-pregister{
        margin-top: 10px !important;
        top:10px !important;
    }
    input, fieldset{
        font-size: 16px !important;
    }
    .tron-logo{
        width: 80px !important;
        height: 20px !important;
    }
    .MuiFormControl-root, .MuiButtonBase-root{
        margin-top:0px !important;
    }
    .MuiInputBase-input {
        height: 10px !important;
    }
    .MuiButtonBase-root{
        height: 43px !important;
    }
}
@media only screen and (max-width: 450px) {
    
.container-preregister{ 
        background: url("../assets/bg-mobile.jpg") no-repeat fixed top / cover;
        height: 200vh;
    }
    .box-preregister{
        width: 90%;
        top:400px !important;
        left:5vw !important;
    }
    .logos{
        margin-top: 15px !important;
        position: absolute !important;
        width: 90%;
        top:-400px;
        align-items: flex-start;
        & .big-font{
            margin-top: -5px !important;
        }
    }
    .tron-logo{
        width: 120px !important;
    }
    .big-font{
        font-size: 20px !important;
    }
    .small-font{
        font-size: 15px !important;
    }
    .height-line-big{
        line-height: 60px !important;
    }
    .name{
        grid-row: 1/2 !important;
        grid-column: 1/3 !important;
        margin-right: 0px !important;
    }
    .email{
        grid-row: 2/3 !important;
        grid-column: 1/3 !important;
        margin-left: 0px !important;
    }
    .cv{
        grid-row: 3/4 !important;
        grid-column: 1/2 !important;
        width: 90% !important;
    }
    .submit-btn{
        grid-row: 3/4 !important;
        grid-column: 2/3 !important;
        width: 90% !important;
        height: 56px !important;
    }
}