p {
    margin-bottom: 0px;
}

.container-admin {
    width: 100vw;
    height: 100vh;
    background: url("../assets/bg.jpg") no-repeat fixed center / cover;
}

div[role="cell"][data-field="cv"] {
    cursor: pointer;
}

@media only screen and (max-width: 1440px) {
    .box-admin {
        width: 90vw !important;
        left: 5% !important;
    }
}
@media only screen and (max-width: 450px) {
    .container-admin {
        width: 200vw !important;
        height: 200vh !important;
    }
    .box-admin {
        width: 90vw !important;
        left: 5% !important;
    }
    .submit-btn-login {
        margin-top: 25px !important;
    }
    .box-login {
        gap: 10px;
        top: 400px !important;
        div {
            gap: 20px;
        }
    }
    .big-font-login {
        font-size: 20px !important;
    }
    .logos {
        width: 100% !important;
    }
}
