body {
    width: 100vw;
    height: 100vh;
    background: url("../assets/main-bg.jpg") no-repeat fixed center / cover;
}
@media only screen and (max-width: 1440px) {
    .big-font-login {
        margin-top: -10px !important;
        font-size: 30px !important;
    }
    .box-login {
        top: 250px !important;
        width: 40vw !important;
    }
}

@media only screen and (max-width: 450px) {
    body {
        background: url("../assets/bg-mobile.jpg") no-repeat fixed top / cover;
    }
    .box-login {
        width: 90vw !important;
        left: 5% !important;
    }
    .submit-btn-login {
        margin-top: 25px !important;
    }
    .box-login {
        gap: 10px;
        top: 400px !important;
        div {
            gap: 20px;
        }
    }
    .big-font-login {
        font-size: 20px !important;
    }
    .logos {
        width: 100% !important;
    }
}
